import {useContext, useEffect, useState} from 'react';
import axios from "axios";
import Helpers from '../components/Helpers';
import {AppContext} from '../components/AppContext';

const useFormRegister = (initialValues, validateRegister, t) => {
    const [inputs, setInputs] = useState(initialValues);
    const [errors, setErrors] = useState([]);
    const [readyToRegisterProcess, setReadyToRegisterProcess] = useState(false);
    const [registerProcessError, setRegisterProcessError] = useState(false);
    const [showError, setShowError] = useState(false);
    const [registerComplete, setRegisterComplete] = useState(false);

    const {apiPrefix, dataAccess, urlsApi} = useContext(AppContext);

    const handleInputChange = (e) => {
        if(e.target.type === "checkbox"){
            setInputs(inputs => ({...inputs, [e.target.name]: !inputs.remember}));
        }else{
            setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
        }
    }
    
    const handleSubmit = (e) => {
      if(e){
        e.preventDefault();
      }

      const validationErrors = validateRegister(inputs, t);
      const noErrors = Object.keys(validationErrors).length === 0;
      if(noErrors){
        console.log("Data in inputs is OK. Go to process register!");
        console.log("Inputs: ", inputs);
        setErrors([]);
        setReadyToRegisterProcess(true);
      }else{
        setErrors(validationErrors);
      }
    }

    /**
    * useEffect - After register process, set default value
    */
    useEffect(() => {
        if(registerProcessError){
            setInputs({name: '',surname:'',email:'',phone:'',password:'',repassword:''});
            setRegisterProcessError(false);
            setReadyToRegisterProcess(false);

            setTimeout(() => {
                setShowError(false);
            },3000);
        }
    },[inputs, registerProcessError]);

    /**
    * useEffect - register process
    */
    useEffect(() => {
        if(readyToRegisterProcess){
            console.log("readyToSendRegister");
            const url = apiPrefix + urlsApi.user.register;

            const {getAxiosConfig} = Helpers;
            const postData = inputs;
            const axiosConfig = getAxiosConfig(dataAccess.app.jwt);

            axios.post(url, postData, axiosConfig)
                .then(res => {
                    console.log("Res register user axios: ", res);
                    setRegisterProcessError(false);

                    setTimeout(() => {
                        setReadyToRegisterProcess(false);
                        setRegisterComplete(true);
                    },2500);
                })
                .catch((error) => {
                    console.log("Erros axios: ", error);
                    if (error.response) {
                        // the request was made and the server responded with a status code
                        console.log("Error response: ", error.response);
                        console.log("Error response status: ", error.response.status);
                    } else if (error.request) {
                        // the request was made but no response was received
                        console.log("network error");
                    } else {
                        // something happened when setting up the request
                        console.log("Error: ", error);
                    }

                    setShowError(true);
                    setRegisterProcessError(true);
                });
        }
    },[readyToRegisterProcess,apiPrefix, dataAccess, inputs, urlsApi]);

    return {
        inputs,
        handleInputChange,
        handleSubmit,
        errors,
        readyToRegisterProcess,
        showError,
        registerComplete
    };
}
export default useFormRegister;