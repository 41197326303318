import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
//import { FontAwesomeIcon } from '@fortawesome/react-solid-svg-icons';
//import { faCoffee } from '@fortawesome/free-solid-svg-icons';
//<FontAwesomeIcon icon={["fa", "arrow-up"]} />
//<FontAwesomeIcon icon={faCoffee} />

const Footer = () => {
    const { t } = useTranslation();

    // The back-to-top button is hidden at the beginning
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 150) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      });
    }, []);

    // This function will scroll the window to the top 
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smoothly scrolling
      });
    };

    const backTopTopButton = (showButton &&
      <p className="float-end mb-1">
        <button className="btn btn-danger btn-floating btn-md" onClick={scrollToTop} id="backToTop">
            <i className="fa fa-arrow-up" aria-hidden="true"></i> Top
        </button>
      </p>
    );

    return ( <div className="container">
    <footer className="py-3 my-4">
      {backTopTopButton}
      <h4 className="text-white">{t("company.name")}</h4>
      <p className="text-muted">{t("company.info")}</p>
      <p className="text-center text-muted">&copy; 2021 Fanti.pl Tomasz Łucka</p>
    </footer>
  </div> );
}
 
export default Footer;