import useFormLogin from '../hooks/useFormLogin';
import { useTranslation } from "react-i18next";
import validateLogin from "../validators/validateLogin";
import ErrorMessage from "../components/ErrorMessage";
import Alert from "../components/Alert";

const FormLogin = () => {
    const { t } = useTranslation();
    const {inputs, handleInputChange, handleSubmit, errors, readyToLoginProcess, showError} = useFormLogin({email:'',password:'',remember:false}, validateLogin, t);

    return (
        <form id="loginForm" onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
                <input type="email" name="email" value={inputs.email} onChange={handleInputChange} className="form-control" placeholder="name@example.com" required=""/>
                <label htmlFor="email">{t("login.fields.label.email_address")}</label>
                {errors.email && <ErrorMessage error={errors.email} />}
            </div>
            <div className="form-floating mb-3">
                <input type="password" name="password" value={inputs.password} onChange={handleInputChange} className="form-control" placeholder="Password" required=""/>
                <label htmlFor="password">{t("login.fields.label.password")}</label>
                {errors.password && <ErrorMessage error={errors.password} />}
            </div>
            <div className="checkbox mb-3">
                <label>
                    <input type="checkbox" name="remember" value="1" onChange={handleInputChange} checked={inputs.remember}/> Remember me
                </label>
            </div>
            {readyToLoginProcess && <h4>Login process ...</h4>}
            {showError && <Alert severity="error">{t("login.form.submit.failed")}</Alert>}
            <button className="btn btn-primary" type="submit">{t("login.form.btn.submit")}</button>
        </form>
    )
}
export default FormLogin;