import useFormRegister from '../hooks/useFormRegister';
import { useTranslation } from "react-i18next";
import validateRegister from "../validators/validateRegister";
import ErrorMessage from "../components/ErrorMessage";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";

const FormRegister = () => {
    const { t } = useTranslation();
    const {inputs, handleInputChange, handleSubmit, errors, readyToRegisterProcess, showError, registerComplete} = useFormRegister({name:'',surname:'',email:'',phone:'',password:'',agree_terms:false}, validateRegister, t);
    const passwordInfo = t("register.form.password.info");

    // After registration
    if(registerComplete){
        return (
            <Alert severity="success">{t("register.form.submit.complete")}</Alert>
        );
    }

    const spinnerInfoProcessRegister = (<><p className="my-2"><div className="spinner-border text-info" role="status"></div></p><Alert severity="info">{t("register.form.process")}</Alert></>);

    return (
        <form id="registerForm" onSubmit={handleSubmit}>
            <div className="form-floating mb-3">
                <input type="text" name="name" value={inputs.name} onChange={handleInputChange} className="form-control" placeholder={t("register.form.fields.label.name")}/>
                <label htmlFor="name">{t("register.form.fields.label.name")}</label>
                {errors.name && <ErrorMessage error={errors.name} />}
            </div>
            <div className="form-floating mb-3">
                <input type="text" name="surname" value={inputs.surname} onChange={handleInputChange} className="form-control" placeholder={t("register.form.fields.label.surname")}/>
                <label htmlFor="surname">{t("register.form.fields.label.surname")}</label>
                {errors.surname && <ErrorMessage error={errors.surname} />}
            </div>
            <div className="form-floating mb-3">
                <input type="email" name="email" value={inputs.email} onChange={handleInputChange} className="form-control" placeholder={t("register.form.fields.label.email")}/>
                <label htmlFor="email">{t("register.form.fields.label.email")}</label>
                {errors.email && <ErrorMessage error={errors.email} />}
            </div>
            <div className="form-floating mb-3">
                <input type="phone" name="phone" value={inputs.phone} onChange={handleInputChange} className="form-control" placeholder={t("register.form.fields.label.phone")}/>
                <label htmlFor="phone">{t("register.form.fields.label.phone")}</label>
                {errors.phone && <ErrorMessage error={errors.phone} />}
            </div>
            <div className="form-floating mb-3">
                <input type="password" name="password" value={inputs.password} onChange={handleInputChange} className="form-control" placeholder={t("register.form.fields.label.password")} required=""/>
                <label htmlFor="password">{t("register.form.fields.label.password")}</label>
                <p className="text-info">{passwordInfo}</p>
                {errors.password && <ErrorMessage error={errors.password} />}
            </div>
            <div className="form-floating mb-3">
                <input type="password" name="repassword" value={inputs.repassword} onChange={handleInputChange} className="form-control" placeholder={t("register.form.fields.label.repassword")} required=""/>
                <label htmlFor="repassword">{t("register.form.fields.label.repassword")}</label>
                {errors.repassword && <ErrorMessage error={errors.repassword} />}
            </div>
            <div className="checkbox mb-3">
                <label>
                    <input type="checkbox" name="agree_terms" value="1" onChange={handleInputChange} checked={inputs.agree_terms}/> {t("register.form.agree_terms.label")} <Link to="/terms-of-service" className="ml-1">{t("regulation.link.name")}</Link>
                </label>
                {errors.agree_terms && <ErrorMessage error={errors.agree_terms} />}
            </div>
            {readyToRegisterProcess && spinnerInfoProcessRegister}
            {showError && <Alert severity="error">{t("register.form.submit.failed")}</Alert>}
            <button className="btn btn-primary" type="submit">{t("register.form.btn.submit")}</button>
        </form>
     );
}
 
export default FormRegister;