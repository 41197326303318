import {useContext, useEffect, useState} from 'react';
import axios from "axios";
import Helpers from '../components/Helpers';
import {AppContext} from '../components/AppContext';

const useFormLogin = (initialValues, validateLogin, t) => {
    const [inputs, setInputs] = useState(initialValues);
    const [errors, setErrors] = useState([]);
    const [readyToLoginProcess, setReadyToLoginProcess] = useState(false);
    const [loginProcessError, setLoginProcessError] = useState(false);
    const [showError, setShowError] = useState(false);

    const {apiPrefix, dataAccess, urlsApi} = useContext(AppContext);

    const handleInputChange = (e) => {
        if(e.target.type === "checkbox"){
            setInputs(inputs => ({...inputs, [e.target.name]: !inputs.remember}));
        }else{
            setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
        }
    }
    
    const handleSubmit = (e) => {
      if(e){
        e.preventDefault();
      }

      const validationErrors = validateLogin(inputs, t);
      const noErrors = Object.keys(validationErrors).length === 0;
      if(noErrors){
        setErrors([]);
        setReadyToLoginProcess(true);
        console.log(inputs);
        console.log("Data in inputs is OK. Use hook useFetch!");
      }else{
        setErrors(validationErrors);
      }
    }

    /**
    * useEffect - After login process, set default value
    */
    useEffect(() => {
        if(loginProcessError){
            setInputs({email:'',password:'',remember:false});
            setLoginProcessError(false);
            setReadyToLoginProcess(false);

            setTimeout(() => {
                setShowError(false);
            },3000);
        }
    },[inputs, loginProcessError]);

    /**
    * useEffect - login process
    */
    useEffect(() => {
        if(readyToLoginProcess){
            console.log("readyToSend");
            const url = apiPrefix + urlsApi.user.login;
                
            const {getAxiosConfig} = Helpers;
            const postData = {
                login: inputs.email,
                password: inputs.password,
            };
            const axiosConfig = getAxiosConfig(dataAccess.app.jwt);

            //const data = useFetch(url, options);
            const data = axios.post(url, postData, axiosConfig)
                .then(res => {
                    console.log("Res login user axios: ", res);
                    setLoginProcessError(false);
                })
                .catch((error) => {
                    console.log("Erros axios: ", error);
                    setLoginProcessError(true);
                    setShowError(true);
                    //setInputs(inputs => ({...inputs, [e.target.name]: !inputs.remember}));

                    if (error.response) {
                        // the request was made and the server responded with a status code
                        console.log("Error response: ", error.response);
                        console.log("Error response status: ", error.response.status);
                    } else if (error.request) {
                        // the request was made but no response was received
                        console.log("network error");
                    } else {
                        // something happened when setting up the request
                        console.log("Error: ", error);
                    }
                });
        }
    },[readyToLoginProcess,apiPrefix, dataAccess, inputs, urlsApi]);

    return {
        inputs,
        handleInputChange,
        handleSubmit,
        errors,
        readyToLoginProcess,
        showError
    };
}
export default useFormLogin;