const validateRegister = (inputs, t) => {
    const errors = {};
    // Validate lowercase letters
    const lowerCaseLetters = /[a-z]/g;
    // Validate capital letters
    const upperCaseLetters = /[A-Z]/g;
    // Validate numbers
    const numbers = /[0-9]/g;
    
    // Email errors
    if (inputs.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)) {
            errors.email = t("register.form.feilds.validate.errors.email.invalid");
        }
    }

    // Phone errors
    if (!inputs.phone) {
        errors.phone = t("register.form.feilds.validate.errors.phone.check");
    } else if(!/^[+]{1}[0-9]{10,}$/i.test(inputs.phone)) {
        errors.phone = t("register.form.feilds.validate.errors.phone.invalid");
    }

    // Password Errors
    if(!inputs.password){
        errors.password = t("register.form.feilds.validate.errors.password.check")
    }else if(!inputs.repassword){
        errors.repassword = t("register.form.feilds.validate.errors.repassword.check")
    }else if(inputs.password.length < 8){
        errors.repassword = t("register.form.feilds.validate.errors.password.length")
    }else if(inputs.password !== inputs.repassword){
        errors.repassword = t("register.form.feilds.validate.errors.passwords.notthesame")
    }else if(!inputs.password.match(lowerCaseLetters)){
        errors.repassword = t("register.form.feilds.validate.errors.password.lowercase")
    }else if(!inputs.password.match(upperCaseLetters)){
        errors.repassword = t("register.form.feilds.validate.errors.password.uppercase")
    }else if(!inputs.password.match(numbers)){
        errors.repassword = t("register.form.feilds.validate.errors.password.numbers")
    }else{
        // Agree Terms
        if(!inputs.agree_terms){
            errors.agree_terms = t("register.form.feilds.validate.errors.agree_terms")
        }
    }

    return errors;
}
export default validateRegister;