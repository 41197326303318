import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Languages from "./Languages";

const LanguageSelector = () => {
  const [language, setLanguage] = useState("pl");
  const { i18n } = useTranslation();

  const changeLanguageSelect = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
      <div className="d-flex justify-content-center">
            <select className="form-control me-2" onChange={changeLanguageSelect} value={language}>
                {Languages.map((language) => <option value={language.code} key={language.code}>{language.value}</option>)}
            </select>
      </div>
  );
};

export default LanguageSelector;