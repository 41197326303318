import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/logo.png";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";

//import css module
import "bootstrap/dist/js/bootstrap.min.js";

const list = [
    { name: "Home", i18n: "header.menu.home", path: "/", class: "nav-link active", exact: true},
    { name: "Login", i18n: "header.menu.login", path: "/login", class: "nav-link"},
    { name: "Reservations", i18n: "header.menu.reservations", path: "/reservations", class: "nav-link" },
    { name: "Price list", i18n: "header.menu.price_list", path: "/price-list", class: "nav-link" },
    { name: "Like on Facebook", path: "https://www.facebook.com/", class: "nav-link", target: "_blank", external: true}
];
const exactDefault = false;

const Header = () => {

    const [isActiveOverlay, setIsActiveOverlay] = useState(false);
    const { t } = useTranslation();

    const toogleClass = (event) => {
        setIsActiveOverlay(!isActiveOverlay);
    }

    const overlayContent = list.map(item => {
        const exactValue = item.exact ? item.exact.toString() : exactDefault;
            const targetValue = item.target ? item.target : "_self";
            const linkHtml = (
                item.external
                ? <a href={item.path} aria-current="page" onClick={toogleClass} target={targetValue} rel="noreferrer noopener" key={item.name}>{t(item.i18n)}</a>
                : <NavLink to={item.path} target={targetValue} aria-current="page" onClick={toogleClass} exact={exactValue.toString()} key={item.name}>{t(item.i18n)}</NavLink>
            );

            return linkHtml;
    });

    return (
        <>
        <nav className="navbar navbar-expand-md navbar-dark bg-dark mb-4">
            <div className="container-fluid">
                <Link to="/" className="navbar-brand d-flex align-items-center">
                    <img src={logo} alt="" className="logo-image" title="Luggage storage" />
                </Link>
                <form className="d-flex">
                    <LanguageSelector />
                </form>
                <button className="navbar-toggler" onClick={toogleClass} type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
        <div id="myNav" className={`overlay ${isActiveOverlay ? "active-overlay" : ""}`}>
            <span type="button" className="closebtn" onClick={toogleClass}>&times;</span>
            <div className="overlay-content">
                {overlayContent}
            </div>
        </div>
</>);
}
 
export default Header;