const validateLogin = (inputs, t) => {
    //Email errors
    const errors = {};

    if (!inputs.email) {
        errors.email = t("login.feilds.validate.errors.email.check");
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ) {
        errors.email = t("login.feilds.validate.errors.email.invalid");
    }

    //Password Errors
    if(!inputs.password  || inputs.password.length<6){
        errors.password = t("login.feilds.validate.errors.password.check")
    }

    return errors;
}
export default validateLogin;