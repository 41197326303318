import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FormRegister from "../../forms/FormRegister";

const Register = () => {
    const { t } = useTranslation();

    return (
        <>
            <h1 className="h3 mb-3 fw-normal">{t("register.header.form")}</h1>
            <div className="mb-4">
                <FormRegister />
            </div>
            <Link to="/login" className="btn btn-outline-info">{t("login.link.redirect")}</Link>
        </>
     );
}
 
export default Register;