import { createContext } from "react";

//const TEST_CONSTANT_VAR = true;

// defualt vlaues
export const objectContext = {
    apiPrefix: "https://odbioromatdevapi.bookinglock.com/v1",
    dataAccess: {
        app: {
            loginSys: "4a5c9c85f91bb2cfc763d4e5477ed1a3",
            passSys: "ZerGAS9myFAU",
            jwt: "",
            odbioromat_id: "",
        },
        user: {
            login: "",
            pass: ""
        }
    },
    isLoginApp: false,
    isLoginUser: false,
    maxCallLogin: 15,
    urlsApi: {
        app: {
            boxes: "/odbioromat/boxes",
            box_free: "/odbioromat/box-free",
            login: "/login",
            modules: "/odbioromat/modules",
            gabaryty: "/odbioromat/gabaryty",
            parcel_add: "/odbioromat/parcel-add",
            parcel_insert: "/odbioromat/parcel-insert",
            parcel_pickup: "/odbioromat/parcel-pickup",
            validate_token: "/validate-token",
        },
        user: {
            login: "/app/user/login",
            register: "/app/user/register",
            reservations: "/reservations",
            pricelist: "/price-list",
        }
    },
};

//console.log("objectContext test: ", objectContext.test);

export const AppContext = createContext(objectContext);