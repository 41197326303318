import { Route, Routes } from 'react-router-dom';
import { useContext } from 'react';
import {AppContext} from './AppContext';

import HomePage from './pages/HomePage';
import ErrorPage from './pages/ErrorPage';
import Login from './pages/Login';
import Register from './pages/Register';
import PriceList from './pages/admin/PriceList';
import Reservations from './pages/admin/Reservations';

const Page = () => {
    //const {test, hello} = useContext(AppContext);

    return ( 
            <main>
                <section className="text-center container">
                <div className="row py-lg-5">
                    <div className="col-lg-6 col-md-8 mx-auto">
                        <Routes>
                            <Route exact path="/" element={<HomePage />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/reservations" element={<Reservations />} />
                            <Route path="/price-list" element={<PriceList />} />
                            <Route element={<ErrorPage />} />
                        </Routes>
                    </div>
                </div>
                </section>
            </main>
     );
}
 
export default Page;