import React, { Suspense, useEffect, useState } from 'react';
import {AppContext, objectContext} from './AppContext';
import Helpers from './Helpers';
import axios from "axios";

import {BrowserRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/App.css';
//import $ from 'jquery';
//import DateTimePicker from 'react-datetime-picker'
import "../i18n";

import Header from "../components/Header";
import Page from "../components/Page";
import Footer from "../components/Footer";

function App() {
  const [ctx, setCtx] = useState(objectContext);
  const [counterLogin, setCounterLogin] = useState(0);
  const [isLoginApp, setIsLoginApp] = useState(false);
  const [jwt, setJwt] = useState(null);
  const {apiPrefix, dataAccess, maxCallLogin, urlsApi} = objectContext;
  const {getAxiosConfig} = Helpers;

  const urlLoginApp = apiPrefix + urlsApi.app.login;
  const postData = {
      login: dataAccess.app.loginSys,
      password: dataAccess.app.passSys,
  };
  const axiosConfig = getAxiosConfig('');

  /**
    Login App to API
   */
  useEffect(() => {
    console.log("use useEffect - login APP to API!");

    if(!isLoginApp && counterLogin < maxCallLogin){
      //const data = useFetch(url, options);
      axios.post(urlLoginApp, postData, axiosConfig)
        .then(res => {
            setIsLoginApp(true);
            setJwt(res.data.jwt);
        })
        .catch((err) => {
            setCounterLogin(counterLogin+1);
        });
    }
  },[counterLogin,isLoginApp,urlLoginApp]);

  /**
    Update context
  */
  useEffect(() => {
    if(isLoginApp) {
      const newCtx = {...ctx, isLoginApp: true, dataAccess: {app: {jwt: jwt}} };
      setCtx(newCtx);
    }
  },[isLoginApp, jwt]);

  return (
    <div>
      <Suspense fallback={null}>
        <Router>
          <div className="app">
            <AppContext.Provider value={ctx}>
              <Header />
                <Page />
              <Footer />
            </AppContext.Provider>
          </div>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
