const Helper = {
    getPostData: (obj) => {
        return JSON.stringify(obj);
    },
    getAxiosConfig: (jwt) => {
        const headersNoAuth = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
        };
        const headersAuth = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + jwt
        };
        
        if(jwt.length > 0){
            return {
                headers: headersAuth
            };
        }else{
            return {
                headers: headersNoAuth
            };
        }
    },
}
export default Helper;