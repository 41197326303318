import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FormLogin from '../../forms/FormLogin';

const Login = () => {
    const { t } = useTranslation();

    return (
        <>
            <h1 className="h3 mb-3 fw-normal">{t("login.header.form")}</h1>
            <div className="mb-4">
                <FormLogin />
            </div>
            <Link to="/register" className="btn btn-outline-info">{t("register.link.redirect")}</Link>
        </>
    );
}
 
export default Login;