import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
//https://www.mindbowser.com/adding-multi-language-support-using-reactjs/

let fullUrl = 'http://localhost:3000';
if(window.location.hostname.includes("bookinglock") | window.location.hostname.includes("localhost")){
  fullUrl = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "pl",   //default language
    fallbackLng: "pl", //when specified language translations not present 

    //then fallbacklang translations loaded.
    debug: false,
    backend: {
      /* translation file path */
      loadPath: fullUrl+"/assets/i18n/{{ns}}/{{lng}}.json",
    },

    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;