import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomePage = () => {
    const { t } = useTranslation();

    return ( <div className="test">
        <h3>{t("header.homepage")}</h3>
        <Link to="/login" className="btn btn-primary my-5 align-items-center">Link to login</Link>
    </div> );
}
 
export default HomePage;